var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--how-does-airalo-work_item how-does-airalo-work-card-item"
  }, [_c('nuxt-img', {
    attrs: {
      "src": _vm.img,
      "width": "195",
      "height": "400",
      "alt": "how does airalo work item",
      "loading": "lazy"
    }
  }), _vm._v(" "), _c('h2', {
    staticClass: "title"
  }, [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }