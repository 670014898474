<template>
  <div class="c--how-does-airalo-work how-does-airalo-work home-container-padding">
    <div class="container-fluid">
      <div class="airgsm-container mx-auto">
        <h2 class="how-does-airalo-work-title title-h2">
          {{ $t('components.how.airalo.works.title') }}
        </h2>
        <div class="how-does-airalo-work-card-wrapper">
          <CardItem
            v-for="(card, index) in cards"
            :key="`how-does-airalo-work-card-${index}`"
            :img="card.img.replace('{direction}', $dir() === 'ltr' ? 'LTR' : 'RTL')"
            :title="card.title"
            :content="card.content"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import CardItem from '~/components/how-does-airalo-work/item'
  export default {
    name: 'HowDoesAiraloWork',
    components: {
      CardItem,
    },
    data() {
      return {
        cards: [
          {
            img: '/assets/images/svg/How-Airalo-Works-{direction}-1.svg',
            title: this.$t('components.how.airalo.works.step-first-title'),
          },
          {
            img: '/assets/images/svg/How-Airalo-Works-{direction}-2.svg',
            title: this.$t('components.how.airalo.works.step-second-title'),
          },
          {
            img: '/assets/images/svg/How-Airalo-Works-{direction}-3.svg',
            title: this.$t('components.how.airalo.works.step-third-title'),
          },
          {
            img: '/assets/images/svg/How-Airalo-Works-{direction}-4.svg',
            title: this.$t('components.how.airalo.works.step-fourth-title'),
          },
        ],
      }
    },
  }
</script>

<style lang="scss" scoped>
  .how-does-airalo-work {
    background-color: #fff;

    &-title {
      color: #4a4a4a;
      font-weight: 600;
      letter-spacing: -0.5px;
      line-height: 32px;
    }

    &-card-wrapper {
      display: grid;
      gap: 30px;
      grid-template-columns: repeat(1, minmax(0, 1fr));

      @media (min-width: 576px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
      }

      @media (min-width: 1024px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
      }
    }
  }
</style>
