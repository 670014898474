<template>
  <div class="c--how-does-airalo-work_item how-does-airalo-work-card-item">
    <nuxt-img :src="img" width="195" height="400" alt="how does airalo work item" loading="lazy" />
    <h2 class="title">
      {{ title }}
    </h2>
  </div>
</template>

<script>
  export default {
    name: 'item',
    props: ['img', 'title', 'content'],
  }
</script>

<style lang="scss" scoped>
  .how-does-airalo-work-card-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

    .img {
      width: 100%;
      max-width: 195px;
      height: auto;
    }
    .title {
      color: #4a4a4a;
      margin: 0;
      margin-top: 20px;
      text-align: center;
      font-size: 1.0625rem;
      font-weight: 500;
      letter-spacing: -0.1px;
      line-height: 1.17647;
      max-width: 240px;

      @media (min-width: 576px) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        max-width: unset;
      }

      @media (min-width: 1024px) {
        font-size: 1.1875rem;
        font-weight: 600;
        letter-spacing: -0.2px;
        line-height: 1.157894;
        max-width: 240px;
      }
    }
  }
</style>
