var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--how-does-airalo-work how-does-airalo-work home-container-padding"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_c('div', {
    staticClass: "airgsm-container mx-auto"
  }, [_c('h2', {
    staticClass: "how-does-airalo-work-title title-h2"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('components.how.airalo.works.title')) + "\n      ")]), _vm._v(" "), _c('div', {
    staticClass: "how-does-airalo-work-card-wrapper"
  }, _vm._l(_vm.cards, function (card, index) {
    return _c('CardItem', {
      key: `how-does-airalo-work-card-${index}`,
      attrs: {
        "img": card.img.replace('{direction}', _vm.$dir() === 'ltr' ? 'LTR' : 'RTL'),
        "title": card.title,
        "content": card.content
      }
    });
  }), 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }